import * as React from "react";
import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
// import { BreadCrumbs } from "@components/Breadcrumbs";
import { Provider } from "jotai";
import { seoAtom, heroAtom } from "@/store";
import { Helmet } from "react-helmet";

export const FeedbackPage = () => {
  const seo = { title: "EP3 Feedback", description: "Feedback Form for EP3 Site" };

  return (
    <Provider
      initialValues={[
        [seoAtom, seo],
        [heroAtom, undefined],
      ]}
    >

      <Helmet>
        <title> Feedback Form | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <Title>Toolkit Feedback Form</Title>
          </Header>
          <iframe
            title="EP3 Feedback Form"
            src="https://docs.google.com/forms/d/e/1FAIpQLScCOPe8bILAkHhgBlEFsJA1iREUT8ZAd69Jud-V_reg3mqzpQ/viewform?embedded=true"
            width="1000"
            height="1400"
            style={{ margin: "auto" }}
            className="w-full"
          >
          </iframe>
        </Container>
      </Layout>
    </Provider>
  );
};
export default FeedbackPage;
